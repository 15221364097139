<template>
  <div class="mt-1 mx-3">
    <edit-unsolved> </edit-unsolved>
  </div>
</template>

<script>
import EditUnsolved from "@/components/accounting/advance-payment/unsolved-payments/edit.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
    ...mapActions("breadcrumbs", { removeBCS: "remove" }),
    ...mapGetters("breadcrumbs", { loadBCS: "loadBreadcrumbs" }),
  },
  components: {
    EditUnsolved,
  },

  beforeMount() {
    let bcs = this.loadBCS();
    if (this.$route.query.fromShortcut) {
      bcs.push({
        text: "Modifica Insoluto",
        path: "unsolved_payments.edit",
        level: 3,
      });
      bcs.forEach((element) => {
        this.notifyQueue({
          text: element.text,
          path: element.path,
          tab: element.tab,
          level: element.level,
          disabled: element.disabled,
        });
      });
      // this.$router.replace({ query: null });
    } else {
      this.notifyQueue({
        text: "Modifica Insoluto",
        path: "unsolved_payments.edit",
        level: 3,
      });
    }
  },
};
</script>
