<template>
  <div class="mt-1 mx-3">
    <unsolved-create> </unsolved-create>
  </div>
</template>

<script>
import UnsolvedCreate from "@/components/accounting/advance-payment/unsolved-payments/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    UnsolvedCreate,
  },
  created() {
    this.notifyQueue({
      text: "Crea Insoluto",
      path: "unsolved_payments.create",
      level: 4,
    });
  },
};
</script>
