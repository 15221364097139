<template>
  <div class="mt-1 mx-3">
    <unsolved-payments></unsolved-payments>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import UnsolvedPayments from "@/components/accounting/advance-payment/unsolved-payments/index.vue";

export default {
  components: { UnsolvedPayments },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({
      text: "Insoluti",
      path: "unsolved_payments.index",
      level: 1,
    });
  },
};
</script>
