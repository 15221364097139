<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <div>
                        <b-button-toolbar>
                          <b-button-group>
                            <base-input
                              name="Anagrafica"
                              :label="beForm[rep].registry_id.label"
                              v-model="form.inpt_label"
                              :rules="getRules('registry_id')"
                              :readonly="true"
                            />
                            <b-button
                              class="mt-4 btn-quick"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              title="Ricerca Veloce"
                              @click="openQuickSearchRegistry"
                              ><b-icon icon="search"></b-icon
                            ></b-button>
                          </b-button-group>
                        </b-button-toolbar>
                      </div>
                    </div>
                    <div class="registry_data col-md-3">
                      <span
                        v-if="registry_data"
                        class="info"
                        v-html="toInfoData(registry_data, 'registry')"
                      >
                      </span>
                    </div>
                    <quick-search-registry-modal
                      ref="quickSearchRegistry"
                      @input="handleRegistryModalInput"
                    >
                    </quick-search-registry-modal>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].title.label"
                        vid="title"
                        label="Descrizione"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="getRules('title')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="status_payment"
                        :name="beForm[rep].status_payment.label"
                        :label="beForm[rep].status_payment.label"
                        v-model="form[rep].status_payment"
                        :options="beForm[rep].status_payment.options"
                        :rules="getRules('status_payment')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="unsolved_at"
                        :name="beForm[rep].unsolved_at.label"
                        label="Data"
                        v-model="form[rep].unsolved_at"
                        :rules="getRules('unsolved_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].amount.label"
                        vid="amount"
                        label="Ammontare"
                        v-model="form[rep].amount"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="getRules('amount')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="outline-lisaweb"
                  size="sm"
                  class="btn-reset float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import FormMixin from "@/mixins/FormMixin";
import BaseInput from "@/components/form/BaseInput";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import BaseSelect from "@/components/form/BaseSelect";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { toInfoData /* , toRelationString */ } from "@/utils/transforms";
export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      isLoading: false,
      repository: "unsolved_payment",
      id: this.$route.params.id,
      registry_data: null,
      form: {
        quick_value: "",
        inpt_label: "",
        unsolved_payment: {
          status_payment: null,
          title: null,
          unsolved_at: null,
          amount: null,
          registry_id: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseDatepicker,
    BaseCurrency,
    BaseSelect,
    QuickSearchRegistryModal,
  },
  methods: {
    toInfoData,
    openQuickSearchRegistry() {
      this.form[this.rep].registry_id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },

    handleRegistryModalInput(value) {
      this.form[this.rep].registry_id = value.id;
      this.registry_data = value;
      this.form.inpt_label = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    onSubmit() {
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Insoluto aggiornato con successo`,
          });
          this.shortcut(
            // "module.CONT.???",
            "book_entries.index",
            null,
            "#UnsolvedPayments",
            "filterUnsolvedPayments"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.isLoading = true;
    this.fetchEditForm(this.repository, this.id).then(() => {
      const Repo = RepositoryFactory.get("registry");
      Repo.show(this.beForm[this.rep].registry_id.value)
        .then((response) => {
          const data = response.data.data;
          this.registry_data = data;
          this.form[this.rep].registry_id =
            this.beForm[this.rep].registry_id.value;
          // this.form.inpt_label =
          //   this.registry_data.status_registry.value === 0
          //     ? this.registry_data.attributables.NAME +
          //       " " +
          //       this.registry_data.attributables.SURN
          //     : this.registry_data.attributables.CNAM;
          this.form.inpt_label = this.toInfoData(
            this.registry_data,
            "registryfullname"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
  watch: {
    "form.inpt_label": {
      deep: true,
      handler() {
        if (!this.form[this.rep].registry_id) {
          this.registry_data = null;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.table-responsive, .b-table-sticky-header) {
  /* overflow: inherit;  unset or auto value is not ok */
  overflow: inherit !important;
  max-height: 500px !important; /* prevent external container to scroll*/
  min-height: 100px !important;
}

:deep(.card-text .row .form-group) {
  margin-bottom: 0rem !important;
}
</style>
